import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

// Helper function to get day of the week
const getDayOfWeek = (isoDateString) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(isoDateString);
  return daysOfWeek[date.getDay()];
};

const SignOutDayChart = ({ data }) => {
  // Aggregate data to count sign-outs per day of the week
  const aggregatedData = data.reduce((acc, record) => {
    const day = getDayOfWeek(record.time_out);
    if (day !== "Saturday" && day !== "Sunday") {
      // Include only weekdays
      acc[day] = (acc[day] || 0) + 1;
    }
    return acc;
  }, {});

  // Convert aggregated data into an array for the chart
  const chartData = Object.entries(aggregatedData).map(([day, count]) => ({
    day,
    count,
  }));

  // Sort days of the week for better visualization
  const orderedDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  chartData.sort(
    (a, b) => orderedDays.indexOf(a.day) - orderedDays.indexOf(b.day)
  );

  return (
    <div style={{ width: "100%", marginBottom: "40px", height: "400px" }}>
      <h2>Sign-Outs by Day of the Week</h2>
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SignOutDayChart;
