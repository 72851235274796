import React from "react";
import {
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";

const SignInButton = ({ id, classId, studentName, idNumber }) => {
  const handleSignIn = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.uid) {
        console.error("User is not authenticated.");
        alert("You must be logged in to sign in a student.");
        return;
      }

      const uid = currentUser.uid;

      // Prompt for ID number
      const enteredId = prompt(`Enter ID for ${studentName}:`);
      if (!enteredId) {
        return;
      }

      // Handle cheat code for signing in a single student
      if (enteredId === "1234") {
        console.log("Cheat code used: Signing in the student.");
        await signInStudent(uid, id, studentName);
        return;
      }

      // Handle cheat code for signing in all students
      if (enteredId === "9876") {
        console.log("Cheat code used: Signing in all students.");
        await signInAllStudents(uid);
        return;
      }

      // Validate the entered ID against the student's ID number
      if (enteredId !== idNumber) {
        alert("Invalid ID number.");
        return;
      }

      // Sign in the student
      await signInStudent(uid, id, studentName);
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const signInStudent = async (uid, studentId, studentName) => {
    try {
      const currentSignOutRef = doc(
        firestore,
        `users/${uid}/currentSignOuts/${studentId}`
      );
      const signOutDataSnapshot = await getDoc(currentSignOutRef);

      if (!signOutDataSnapshot.exists()) {
        console.error("Sign-out record not found.");
        alert(`Sign-out record not found for ${studentName}.`);
        return;
      }

      const signOutData = signOutDataSnapshot.data();

      // Write to signedInHistory
      const signedInHistoryRef = doc(
        firestore,
        `users/${uid}/signedInHistory/${studentId}`
      );
      await setDoc(signedInHistoryRef, {
        ...signOutData,
        time_in: new Date().toISOString(),
      });

      // Remove from currentSignOuts
      await deleteDoc(currentSignOutRef);

      console.log(`${studentName} signed in successfully!`);
    } catch (error) {
      console.error(`Error signing in ${studentName}:`, error);
    }
  };

  const signInAllStudents = async (uid) => {
    try {
      const currentSignOutsCollection = collection(
        firestore,
        `users/${uid}/currentSignOuts`
      );
      const signOutsSnapshot = await getDocs(currentSignOutsCollection);

      if (signOutsSnapshot.empty) {
        console.log("No students are currently signed out.");
        alert("No students are currently signed out.");
        return;
      }

      const batch = signOutsSnapshot.docs.map(async (docSnap) => {
        const signOutData = docSnap.data();
        const studentId = docSnap.id;

        // Write to signedInHistory
        const signedInHistoryRef = doc(
          firestore,
          `users/${uid}/signedInHistory/${studentId}`
        );
        await setDoc(signedInHistoryRef, {
          ...signOutData,
          time_in: new Date().toISOString(),
        });

        // Remove from currentSignOuts
        await deleteDoc(docSnap.ref);
      });

      await Promise.all(batch);

      console.log("All students signed in successfully!");
      alert("All students signed in successfully!");
    } catch (error) {
      console.error("Error signing in all students:", error);
    }
  };

  return <button onClick={handleSignIn}>Sign In</button>;
};

export default SignInButton;
