// StudentHelpers.js

/**
 * Parses a student list string into an array of student objects.
 * Format: Name | ID | Grade
 * @param {string} list
 * @returns {Array} Parsed student objects
 */
export const parseStudentList = (list) => {
  const lines = list.split("\n").filter((line) => line.trim() !== "");
  return lines.map((line) => {
    const [fullName, id, grade] = line.split("\t");
    const [firstName, ...lastNameArray] = fullName.split(" ");
    const lastName = lastNameArray.join(" ");
    return {
      studentName: fullName.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      id: id.trim(),
      grade: grade.trim(),
    };
  });
};

/**
 * Validates each student object to ensure it has required fields.
 * @param {Array} students
 * @returns {Array} Students with a validation status
 */
export const validateStudents = (students) => {
  const validGrades = [
    "Pre-K",
    "Kindergarten",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  return students.map((student) => {
    const isValidGrade = validGrades.includes(student.grade);
    const isValid = student.studentName && student.id && isValidGrade; // Ensure the grade is valid
    return { ...student, isValid };
  });
};
