import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  doc,
  updateDoc,
  deleteDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "./UserContext";

const ArchivedClasses = () => {
  const { user, schoolId } = useContext(UserContext);
  const [archivedClasses, setArchivedClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArchivedClasses = async () => {
      try {
        const db = getFirestore();
        const classesRef = collection(db, "classes");
        const archivedQuery = query(
          classesRef,
          where("schoolId", "==", schoolId),
          where("isArchived", "==", true)
        );

        const querySnapshot = await getDocs(archivedQuery);
        const classes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setArchivedClasses(classes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching archived classes:", error);
        setLoading(false);
      }
    };

    fetchArchivedClasses();
  }, [schoolId]);

  const handleRestoreClass = async (classId) => {
    const confirmRestore = window.confirm(
      "Are you sure you want to restore this class? It will move back to active classes."
    );

    if (!confirmRestore) return;

    try {
      const db = getFirestore();
      const classRef = doc(db, "classes", classId);

      // Update the class to mark it as not archived
      await updateDoc(classRef, { isArchived: false });

      // Remove from the user's archivedClasses and add to activeClasses
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        archivedClasses: arrayRemove(classId),
        activeClasses: arrayUnion(classId),
      });

      // Remove the restored class from the local state
      setArchivedClasses(archivedClasses.filter((cls) => cls.id !== classId));
    } catch (error) {
      console.error("Error restoring class:", error);
    }
  };

  const handleDeleteClass = async (classId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this class? This action cannot be undone."
    );

    if (!confirmDelete) return;

    try {
      const db = getFirestore();
      const classRef = doc(db, "classes", classId);

      // Delete the class document
      await deleteDoc(classRef);

      // Update user's archivedClasses
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        archivedClasses: arrayRemove(classId),
      });

      // Remove the deleted class from the local state
      setArchivedClasses(archivedClasses.filter((cls) => cls.id !== classId));
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  const handleViewArchivedClasses = () => {
    navigate("/displayclasses");
  };

  const handleDownloadData = (classData) => {
    const dataStr = JSON.stringify(classData, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${classData.className}_data.json`;
    a.click();

    URL.revokeObjectURL(url);
    console.log("Class data downloaded.");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Archived Classes</h2>
      {archivedClasses.length === 0 ? (
        <p>No archived classes found.</p>
      ) : (
        <ul>
          {archivedClasses.map((classData) => (
            <li key={classData.id}>
              <strong>{classData.className}</strong> - {classData.schoolYear} -
              Grade: {classData.gradeLevel}
              <div className="class-actions">
                <button onClick={() => handleRestoreClass(classData.id)}>
                  Restore
                </button>
                <button
                  className="green-button"
                  onClick={() => handleDownloadData(classData)}
                >
                  Download Data
                </button>
                <button
                  className="red-button"
                  onClick={() => handleDeleteClass(classData.id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      <button onClick={handleViewArchivedClasses}>View Active Classes</button>
    </div>
  );
};

export default ArchivedClasses;
