import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, children, type }) => {
  if (!isOpen) return null;

  // Determine class based on the type or custom className
  const modalClass = type === "insight" ? "modal-content-2" : "modal-content";

  return (
    <div className="modal-overlay">
      <div className={modalClass}>
        {children}
        <button onClick={onClose} className="cancel-button">
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
