import React from "react";
import { createRoot } from "react-dom/client"; // Update the import statement
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./components/UserContext";
// Import global and component styles
import "./styles/global.css";
import "./styles/components.css";

createRoot(document.getElementById("root")).render(
  // Use createRoot
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();
