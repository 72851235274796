import React from "react";
import { NavLink } from "react-router-dom";
import "./LeftSidePanel.css";

const LeftSidePanel = () => {
  return (
    <div className="left-side-panel">
      <ul className="nav-list">
        <li>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/displayclasses"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Classes
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/invitations"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Invitations
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/displayenrolledstudents"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Students
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/signoutsteacherrecord"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Sign-Out History
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/attendanceviewer"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Attendance History
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive ? "nav-link active-link" : "nav-link"
            }
          >
            Settings
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default LeftSidePanel;
