// Dashboard.js
import React, { useContext } from "react";
import { UserContext } from "./UserContext";
import DisplayClasses from "./DisplayClasses";
import Invitations from "./Invitations";

const Dashboard = () => {
  const { user } = useContext(UserContext);

  const welcomeMessage = () => {
    if (user) {
      const { displayName } = user;
      return `Welcome, ${displayName}!`;
    } else {
      return "Loading user data...";
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard-content">
        <h2></h2>
        <h1>{welcomeMessage()}</h1>
        {/* Display the current classes */}
        <DisplayClasses user={user} />
        <Invitations user={user} />
      </div>
    </div>
  );
};

export default Dashboard;
