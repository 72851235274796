import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { auth } from "./FirebaseConfig";
import { UserContext } from "./UserContext.js"; // Import the UserContext

const AddClass = () => {
  const [className, setClassName] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const currentYear = new Date().getFullYear();
  const [schoolYear, setSchoolYear] = useState(
    `${currentYear}-${currentYear + 1}`
  );
  const [roomNumber, setRoomNumber] = useState("");

  const navigate = useNavigate();
  const { schoolId } = useContext(UserContext); // Destructure the schoolId from the context

  useEffect(() => {
    // You can set the default value of schoolId here if needed
    // For example, if the user's schoolId is not yet available in the context
    // setSchoolId("defaultSchoolId");
  }, [schoolId]);

  const handleAddClass = async (e) => {
    e.preventDefault();

    try {
      const db = getFirestore();

      // Get the user's Firestore document
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      // Add the new class document to the "classes" collection
      const classesCollectionRef = collection(db, "classes");
      const newClassData = {
        className,
        gradeLevel,
        schoolYear,
        roomNumber, // Include the room number
        teacherId: userData.uid,
        schoolId: userData.schoolId,
      };

      const newClassDocRef = await addDoc(classesCollectionRef, newClassData);

      // Add the class ID to the user's "activeClasses" array
      await updateDoc(userRef, {
        activeClasses: arrayUnion(newClassDocRef.id),
      });

      console.log("Class added successfully!", auth.currentUser.uid);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error adding class:", error);
    }
  };

  return (
    <div className="form-container">
      <h2>Add New Class</h2>
      <form onSubmit={handleAddClass}>
        <div>
          <label>Class Name:</label>
          <input
            type="text"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Grade Level:</label>
          <input
            type="text"
            value={gradeLevel}
            onChange={(e) => setGradeLevel(e.target.value)}
            required
          />
        </div>
        <div>
          <label>School Year:</label>
          <input
            type="text"
            value={schoolYear}
            onChange={(e) => setSchoolYear(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Room Number:</label>
          <input
            type="text"
            value={roomNumber}
            onChange={(e) => setRoomNumber(e.target.value)}
            required
          />
        </div>{" "}
        <button type="submit">Add Class</button>
      </form>
    </div>
  );
};

export default AddClass;
