import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { collection, doc, getDocs } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import "./AttendanceViewer.css";

const AttendanceViewer = () => {
  const { classList } = useFetchClasses();
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [presentStudents, setPresentStudents] = useState([]);
  const [notPresentStudents, setNotPresentStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceHistory, setAttendanceHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // Ensure `selectedClassId` is set
  useEffect(() => {
    if (!selectedClassId && classList.length > 0) {
      setSelectedClassId(classList[0].id);
      console.log("Default Class ID Set:", classList[0].id);
    }
  }, [classList, selectedClassId]);

  // Fetch attendance data for the selected date
  const fetchAttendanceByDate = useCallback(async () => {
    if (!selectedClassId || !selectedDate) {
      console.log("Class ID or Date not selected. Skipping fetch.");
      return;
    }

    try {
      const formattedDate = selectedDate.toISOString().slice(0, 10);
      console.log("Fetching attendance for Class:", selectedClassId);
      console.log("Fetching attendance for Date:", formattedDate);

      const classRef = doc(firestore, "classes", selectedClassId);
      const attendanceCollectionRef = collection(classRef, "attendance");
      const attendanceDocRef = doc(attendanceCollectionRef, formattedDate);

      // Fetch students who attended
      const attendanceSnapshot = await getDocs(
        collection(attendanceDocRef, "students")
      );

      const attendedStudentIds = attendanceSnapshot.docs.map((doc) => ({
        id: doc.id,
        timestamp: doc.data().timestamp?.toDate() || null,
      }));

      // Fetch students enrolled in the selected class
      const studentsCollectionRef = collection(firestore, "students");
      const studentsSnapshot = await getDocs(studentsCollectionRef);

      const allStudents = studentsSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((student) => student.enrolledClasses.includes(selectedClassId)); // Filter by enrolled classes

      console.log("Filtered Students for Class:", allStudents);

      // Divide students into present and not present
      const present = allStudents
        .filter((student) =>
          attendedStudentIds.some((attendee) => attendee.id === student.id)
        )
        .map((student) => {
          const attendanceData = attendedStudentIds.find(
            (attendee) => attendee.id === student.id
          );
          return { ...student, timestamp: attendanceData?.timestamp };
        });

      const notPresent = allStudents.filter(
        (student) =>
          !attendedStudentIds.some((attendee) => attendee.id === student.id)
      );

      setPresentStudents(present);
      setNotPresentStudents(notPresent);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  }, [selectedClassId, selectedDate]);

  // Automatically fetch attendance data when date or class changes
  useEffect(() => {
    fetchAttendanceByDate();
  }, [fetchAttendanceByDate]);

  // Sort students by last name
  const sortByLastName = (students) => {
    return [...students].sort((a, b) => {
      const lastNameA = a.lastName?.toLowerCase() || "";
      const lastNameB = b.lastName?.toLowerCase() || "";
      return lastNameA.localeCompare(lastNameB);
    });
  };

  const sortedPresentStudents = sortByLastName(presentStudents);
  const sortedNotPresentStudents = sortByLastName(notPresentStudents);

  return (
    <div className="add-student-form-container">
      <h2 style={{ color: "#59738d" }}>Attendance Viewer</h2>

      {/* Class Selection */}
      <div
        className="side-by-side-container"
        style={{ width: "40%", margin: "0 auto", boxSizing: "border-box" }}
      >
        {" "}
        <label htmlFor="class-select" style={{ color: "#333333" }}>
          Select Class:
        </label>
        <select
          id="class-select"
          value={selectedClassId}
          onChange={(e) => setSelectedClassId(e.target.value)}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #d1d5db",
          }}
        >
          {classList.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </select>
      </div>

      {/* Calendar */}
      <div className="table-container">
        <Calendar
          onChange={(date) => {
            setSelectedDate(date);
            console.log("Selected Date:", date);
          }}
          value={selectedDate}
          className="react-calendar"
        />
      </div>

      {/* Attendance Table */}
      <div className="table-container">
        <h3 style={{ color: "#59738d" }}>
          Attendance for {selectedDate.toDateString()}
        </h3>
        <table className="table">
          <thead>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Status</th>
              <th>Check-In Time</th>
            </tr>
          </thead>
          <tbody>
            {sortedPresentStudents.map((student) => (
              <tr key={student.id}>
                <td>{student.lastName}</td>
                <td>{student.firstName}</td>
                <td style={{ color: "green" }}>Present</td>
                <td>
                  {student.timestamp
                    ? new Date(student.timestamp).toLocaleTimeString()
                    : "-"}
                </td>
              </tr>
            ))}
            {sortedNotPresentStudents.map((student) => (
              <tr key={student.id}>
                <td>{student.lastName}</td>
                <td>{student.firstName}</td>
                <td style={{ color: "red" }}>Absent</td>
                <td>-</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={() => setShowModal(false)}>Close</button>
      </div>
    </div>
  );
};

export default AttendanceViewer;
