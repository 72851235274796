import React, { useState, useContext, useEffect } from "react";
import {
  doc,
  addDoc,
  collection,
  getDoc,
  updateDoc,
  arrayUnion,
  getFirestore,
} from "firebase/firestore";
import { UserContext } from "./UserContext";
import PreviewAdjustModal from "./PreviewAdjustModal";
import { validateStudents } from "./StudentHelpers";

const AddStudentFile = ({ classId, onStudentsAdded, onComplete }) => {
  const { user } = useContext(UserContext);
  const [parsedStudents, setParsedStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);

  useEffect(() => {
    if (!user?.schoolId) {
      const fetchSchoolId = async () => {
        try {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setSchoolId(userDoc.data().schoolId);
          } else {
            console.error("User document not found in Firestore.");
          }
        } catch (error) {
          console.error("Error fetching schoolId:", error);
        }
      };

      fetchSchoolId();
    } else {
      setSchoolId(user.schoolId);
    }
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("Please upload a file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      const students = text
        .split("\n")
        .map((line) => {
          const [fullName, studentId, grade] = line
            .split(",")
            .map((str) => str.trim());
          const [firstName, ...lastNameArray] = fullName.split(" ");
          return {
            studentName: fullName,
            firstName,
            lastName: lastNameArray.join(" "),
            id: studentId,
            grade,
            isValid: Boolean(fullName && studentId && grade),
          };
        })
        .filter((student) => student.isValid);

      const validatedStudents = validateStudents(students);
      setParsedStudents(validatedStudents);
      setIsModalOpen(true);
    };

    reader.readAsText(file);
  };

  const handleSave = async (updatedStudents) => {
    const validStudents = updatedStudents.filter((student) => student.isValid);

    if (!classId || validStudents.length === 0) {
      alert("No class selected or no valid students to import.");
      return;
    }

    if (!schoolId) {
      alert("School ID is missing. Please ensure your profile is complete.");
      return;
    }

    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", classId);

      const newStudentIds = [];
      for (const student of validStudents) {
        const newStudentData = {
          firstName: student.firstName,
          lastName: student.lastName,
          studentName: `${student.firstName} ${student.lastName}`,
          studentId: student.id,
          grade: student.grade,
          enrolledClasses: [classId],
          schoolId,
          addedBy: user.uid,
        };

        const studentDocRef = await addDoc(
          collection(db, "students"),
          newStudentData
        );
        newStudentIds.push(studentDocRef.id);
      }

      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(...newStudentIds),
      });

      alert(`${validStudents.length} students were successfully imported.`);
      setParsedStudents([]);
      setIsModalOpen(false);

      if (onStudentsAdded) {
        onStudentsAdded(); // Trigger refresh
      }

      if (onComplete) {
        onComplete(); // Notify parent to reset view
      }
    } catch (error) {
      console.error("Error during bulk import:", error);
      alert("Failed to import students. Please try again.");
    }
  };

  return (
    <div className="file-upload-form">
      <h2>Upload Students from File</h2>
      <div>
        <label htmlFor="fileUpload">Upload CSV File:</label>
        <input
          type="file"
          id="fileUpload"
          accept=".csv"
          onChange={handleFileChange}
        />
      </div>

      {isModalOpen && (
        <PreviewAdjustModal
          parsedStudents={parsedStudents}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AddStudentFile;
