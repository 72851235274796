import React, { useState, useRef, useContext, useEffect } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  setDoc,
  writeBatch, // Import Firestore batch function
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import useFilteredEmergencyStudents from "./useFilteredEmergencyStudents";
import { UserContext } from "./UserContext";
import "./Emergency.css";

const Emergency = () => {
  const [selectedClassId, setSelectedClassId] = useState("");
  const [studentIdInput, setStudentIdInput] = useState("");
  const [showAccountedModal, setShowAccountedModal] = useState(false);
  const { classList } = useFetchClasses();
  const {
    notAccountedForStudents,
    accountedForStudents,
    refreshFilteredEmergencyStudents,
  } = useFilteredEmergencyStudents(selectedClassId);
  const studentIdInputRef = useRef();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!selectedClassId && classList.length > 0) {
      setSelectedClassId(classList[0].id);
    }
  }, [classList, selectedClassId]);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const markEmergency = async (sid, isAccountedFor) => {
    try {
      if (!selectedClassId) return;

      const classRef = doc(firestore, "classes", selectedClassId);
      const emergencyCollectionRef = collection(classRef, "emergencies");
      const currentDate = new Date().toISOString().slice(0, 10);
      const emergencyDocRef = doc(emergencyCollectionRef, currentDate);

      const emergencyData = {
        timestamp: serverTimestamp(),
        sid: sid,
        clickedBy: isAccountedFor ? null : user.displayName,
      };

      const studentEmergencyCollectionRef = collection(
        emergencyDocRef,
        "students"
      );
      const studentEmergencyDocRef = doc(studentEmergencyCollectionRef, sid);

      if (isAccountedFor) {
        await deleteDoc(studentEmergencyDocRef);
      } else {
        await setDoc(studentEmergencyDocRef, emergencyData, { merge: true });
      }

      refreshFilteredEmergencyStudents();
    } catch (error) {
      console.error("Error marking/deleting emergency:", error);
    }
  };

  const resetAllStudents = async () => {
    try {
      if (!selectedClassId) return;

      const classRef = doc(firestore, "classes", selectedClassId);
      const emergencyCollectionRef = collection(classRef, "emergencies");
      const currentDate = new Date().toISOString().slice(0, 10);
      const emergencyDocRef = doc(emergencyCollectionRef, currentDate);

      const studentEmergencyCollectionRef = collection(
        emergencyDocRef,
        "students"
      );

      // Batch deletion
      const accountedSnapshot = await getDocs(studentEmergencyCollectionRef);
      const batch = writeBatch(firestore); // Corrected Firestore batch creation
      accountedSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      refreshFilteredEmergencyStudents();
    } catch (error) {
      console.error("Error resetting all students:", error);
    }
  };

  const handleStudentIdInput = (event) => setStudentIdInput(event.target.value);

  const handleMarkByStudentId = async () => {
    if (!selectedClassId) return;

    try {
      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("studentId", "==", studentIdInput.trim())
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) return;

      const studentDoc = querySnapshot.docs[0];
      const sid = studentDoc.id;

      markEmergency(sid);
      setStudentIdInput("");
      studentIdInputRef.current.focus();
    } catch (error) {
      console.error("Error marking emergency by ID:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") handleMarkByStudentId();
  };

  const formatTimestamp = (timestamp) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(timestamp).toLocaleString(undefined, options);
  };

  return (
    <div className="emergency-container">
      <h2>Student Checklist</h2>

      {/* Class Selection */}
      <div>
        <label>Class:</label>
        {classList.length > 1 ? (
          <select
            className="class-select"
            value={selectedClassId}
            onChange={handleClassChange}
          >
            {classList.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.className}
              </option>
            ))}
          </select>
        ) : (
          <span>{classList[0]?.className || "No classes available"}</span>
        )}
      </div>

      {/* Not Accounted For Students */}
      <div className="student-heading"></div>
      <div className="student-grid">
        {notAccountedForStudents.map((student) => (
          <div
            key={student.id}
            className="student-item-not-accounted"
            onClick={() => markEmergency(student.id)}
          >
            <span className="student-name">{student.studentName}</span>
          </div>
        ))}
      </div>

      {/* Accounted For Students Modal */}
      {showAccountedModal && (
        <div className="modal-overlay">
          <div className="modal-content-2">
            <h2>Accounted Students</h2> {/* Modal title */}
            <div className="modal-grid">
              {accountedForStudents.map((student) => (
                <div
                  key={student.id}
                  className="modal-student-item"
                  onClick={() => markEmergency(student.id, true)}
                >
                  <span className="student-name">{student.studentName}</span>
                  <span className="clicked-by">{student.clickedBy}</span>
                  <span className="timestamp smaller-timestamp">
                    {formatTimestamp(student.timestamp)}
                  </span>
                </div>
              ))}
            </div>
            <div className="modal-buttons">
              <button
                className="red-button" // Reset inside modal
                onClick={resetAllStudents}
              >
                Reset All Students
              </button>
              <button onClick={() => setShowAccountedModal(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        onClick={() => setShowAccountedModal(true)}
        style={{ margin: "20px" }}
      >
        View Accounted Students
      </button>
    </div>
  );
};

export default Emergency;
