import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const SignOutLocationChart = ({ data }) => {
  // Aggregate data to count sign-outs per location
  const aggregatedData = data.reduce((acc, record) => {
    const location = record.location || "Unknown";
    acc[location] = (acc[location] || 0) + 1;
    return acc;
  }, {});

  // Convert aggregated data into an array for the chart
  const chartData = Object.entries(aggregatedData).map(([location, count]) => ({
    location,
    count,
  }));

  return (
    <div style={{ width: "100%", marginBottom: "40px", height: "400px" }}>
      <h2>Sign-Outs by Location</h2>
      <ResponsiveContainer>
        <BarChart data={chartData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="location" type="category" width={150} />
          <Tooltip />
          <Bar dataKey="count" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SignOutLocationChart;
