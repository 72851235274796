import React, { useState } from "react";

import t1 from "../images/T1.png";
import t2 from "../images/T2.png";
import t4 from "../images/T4.png";
import rectangle from "../images/Rectangle 21.png";
import "./MiddleContent.css";

const MiddleContent = () => {
  return (
    <div className="middle-content">
      <div className="image-container">
        <img className="t-image" src={t2} alt="T1" />
        <img className="t-image" src={t1} alt="T2" />
        <img className="t-image" src={t4} />
      </div>
      <div className="text-container">
        <img className="rectangle left-aligned" src={rectangle} />
        <br></br>
        <h2>Created by Teachers, for Teachers</h2>
        <p>
          classBoon was designed by a 5th-grade teacher to streamline student
          sign-outs and keep track of their whereabouts effortlessly.
        </p>
      </div>
    </div>
  );
};

export default MiddleContent;
