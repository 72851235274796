import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const formatTimePeriod = (hour) => {
  const startHour = hour % 12 || 12;
  const endHour = (hour + 1) % 12 || 12;
  const startPeriod = hour >= 12 ? "PM" : "AM";
  const endPeriod = hour + 1 >= 12 ? "PM" : "AM";
  return `${startHour}:00${startPeriod}-${endHour}:00${endPeriod}`;
};

const SignOutTimePeriodChart = ({ data }) => {
  const aggregatedData = data.reduce((acc, record) => {
    const hour = new Date(record.time_out).getHours();
    const period = formatTimePeriod(hour);
    acc[hour] = (acc[hour] || 0) + 1;
    return acc;
  }, {});

  // Convert aggregated data into an array and sort it by hour
  const chartData = Object.entries(aggregatedData)
    .map(([hour, count]) => ({
      hour: parseInt(hour, 10), // Ensure numeric sorting
      period: formatTimePeriod(parseInt(hour, 10)),
      count,
    }))
    .sort((a, b) => a.hour - b.hour); // Sort by numeric hour value

  return (
    <div style={{ width: "100%", marginBottom: "40px", height: "400px" }}>
      <h2>Sign-Outs by Time Period</h2>
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SignOutTimePeriodChart;
