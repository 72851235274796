import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  getFirestore,
} from "firebase/firestore";

const DisplayEnrolledStudentsTable = ({
  classList,
  selectedClass,
  onStudentsUpdated,
}) => {
  const [students, setStudents] = useState([]);
  const [editingStudent, setEditingStudent] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchEnrolledStudents = async () => {
      if (!selectedClass) return;

      try {
        const classDocRef = doc(db, "classes", selectedClass);
        const classDoc = await getDoc(classDocRef);

        if (classDoc.exists()) {
          const classData = classDoc.data();
          const enrolledStudentIds = classData.enrolledStudents || [];

          console.log("Enrolled Student IDs:", enrolledStudentIds);

          const studentDetails = await Promise.all(
            enrolledStudentIds.map(async (studentId) => {
              const studentDocRef = doc(db, "students", studentId);
              const studentDoc = await getDoc(studentDocRef);

              if (!studentDoc.exists()) {
                console.warn(
                  `Student document with ID ${studentId} does not exist.`
                );
                return null;
              }

              const studentData = studentDoc.data();
              if (!studentData.lastName) {
                console.warn(
                  `Student with ID ${studentId} is missing 'lastName' field.`
                );
              }

              return { id: studentId, ...studentData };
            })
          );

          // Filter out invalid or null students and sort by lastName
          const sortedStudents = studentDetails
            .filter((student) => student && student.lastName) // Ensure valid students
            .sort((a, b) => a.lastName.localeCompare(b.lastName));

          setStudents(sortedStudents);
        } else {
          console.error(
            `Class document with ID ${selectedClass} does not exist.`
          );
        }
      } catch (error) {
        console.error("Error fetching enrolled students:", error);
      }
    };

    fetchEnrolledStudents();
  }, [db, selectedClass, onStudentsUpdated]);

  const handleDelete = async (studentId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );
    if (!confirmDelete) return;

    try {
      const studentDocRef = doc(db, "students", studentId);
      await deleteDoc(studentDocRef);

      const classDocRef = doc(db, "classes", selectedClass);
      const classDoc = await getDoc(classDocRef);
      const classData = classDoc.data();

      const updatedEnrolledStudents = classData.enrolledStudents.filter(
        (id) => id !== studentId
      );

      await updateDoc(classDocRef, {
        enrolledStudents: updatedEnrolledStudents,
      });

      setStudents((prev) => prev.filter((student) => student.id !== studentId));
    } catch (error) {
      console.error("Error deleting student:", error);
    }
  };

  const handleSaveEdit = async (studentId, updatedData) => {
    try {
      const studentDocRef = doc(db, "students", studentId);
      await updateDoc(studentDocRef, updatedData);

      setStudents((prev) =>
        prev.map((student) =>
          student.id === studentId ? { ...student, ...updatedData } : student
        )
      );
      setEditingStudent(null);
    } catch (error) {
      console.error("Error updating student:", error);
    }
  };

  return (
    <div className="table-container">
      <h2>Enrolled Students</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Student ID</th>
            <th>Grade</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 ? (
            students.map((student) =>
              editingStudent?.id === student.id ? (
                <tr key={student.id}>
                  <td>
                    <input
                      type="text"
                      defaultValue={student.lastName}
                      onChange={(e) =>
                        setEditingStudent((prev) => ({
                          ...prev,
                          lastName: e.target.value,
                        }))
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={student.firstName}
                      onChange={(e) =>
                        setEditingStudent((prev) => ({
                          ...prev,
                          firstName: e.target.value,
                        }))
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={student.studentId}
                      onChange={(e) =>
                        setEditingStudent((prev) => ({
                          ...prev,
                          studentId: e.target.value,
                        }))
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      defaultValue={student.grade}
                      onChange={(e) =>
                        setEditingStudent((prev) => ({
                          ...prev,
                          grade: e.target.value,
                        }))
                      }
                    />
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        handleSaveEdit(student.id, {
                          lastName: editingStudent.lastName,
                          firstName: editingStudent.firstName,
                          studentName: `${editingStudent.firstName} ${editingStudent.lastName}`,
                          studentId: editingStudent.studentId,
                          grade: editingStudent.grade,
                        })
                      }
                    >
                      Save
                    </button>
                    <button onClick={() => setEditingStudent(null)}>
                      Cancel
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={student.id}>
                  <td>{student.firstName}</td>
                  <td>{student.lastName}</td>
                  <td>{student.studentId}</td>
                  <td>{student.grade}</td>
                  <td>
                    <button onClick={() => setEditingStudent(student)}>
                      Edit
                    </button>
                    <button
                      className="red-button"
                      onClick={() => handleDelete(student.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              )
            )
          ) : (
            <tr>
              <td colSpan="5">No students enrolled</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayEnrolledStudentsTable;
