import React, { useState, useEffect } from "react";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";
import AllUserClassesWithStudents from "./AllUserClassesWithStudents";
import useFetchClasses from "./useFetchClasses";
import "./AddStudent.css";

const DisplayEnrolledStudents = ({ userId }) => {
  const [selectedClass, setSelectedClass] = useState(null);
  const { classList: userClasses } = useFetchClasses();
  const [userClassesWithStudents, setUserClassesWithStudents] = useState([]);
  const [combinedClassList, setCombinedClassList] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchUserClassesWithStudents = async () => {
        try {
          const classesWithStudents = await AllUserClassesWithStudents({
            userId,
          });
          setUserClassesWithStudents(classesWithStudents);
        } catch (error) {
          console.error("Error fetching user classes with students:", error);
        }
      };

      fetchUserClassesWithStudents();
    }
  }, [userId]);

  useEffect(() => {
    const fetchOtherClasses = async () => {
      try {
        const otherClasses = userClassesWithStudents.filter(
          (classData) =>
            !userClasses.some((userClass) => userClass.id === classData.id)
        );
        const combined = [...userClasses, ...otherClasses];
        setCombinedClassList(combined);

        if (combined.length > 0 && !selectedClass) {
          setSelectedClass(combined[0].id);
        }
      } catch (error) {
        console.error("Error fetching other classes:", error);
      }
    };

    fetchOtherClasses();
  }, [userClasses, userClassesWithStudents, selectedClass]);

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  return (
    <div className="add-student-form-container">
      <h1>Enrolled Students</h1>
      <div className="import-method-buttons">
        <select
          id="classDropdown"
          value={selectedClass}
          onChange={handleClassChange}
          className="class-select"
        >
          {combinedClassList.map((classData) => (
            <option key={classData.id} value={classData.id}>
              {classData.className}
            </option>
          ))}
        </select>
      </div>

      {selectedClass ? (
        <DisplayEnrolledStudentsTable
          classList={
            selectedClass === "all"
              ? userClassesWithStudents.flat()
              : combinedClassList
          }
          selectedClass={selectedClass}
        />
      ) : (
        <p>Please select a class.</p>
      )}
    </div>
  );
};

export default DisplayEnrolledStudents;
