import React, { useState } from "react";
import AddStudentSingle from "./AddStudentSingle";
import AddStudentList from "./AddStudentList";
import AddStudentFile from "./AddStudentFile";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";
import Modal from "./Modal"; // Import the Modal component
import useFetchClasses from "./useFetchClasses";
import { useParams } from "react-router-dom";
import "./AddStudent.css";

const AddStudent = () => {
  const { classList } = useFetchClasses();
  const { classId } = useParams();
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshStudentTable = () => {
    setTimeout(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000); // 1-second delay
  };

  return (
    <div className="add-student-form-container">
      <h1>Add Students</h1>
      <div className="import-method-buttons">
        <button onClick={() => setIsSingleModalOpen(true)}>
          Add Single Student
        </button>
        <button onClick={() => setIsListModalOpen(true)}>
          Add List of Students
        </button>
        <button onClick={() => setIsFileModalOpen(true)}>
          Import from File
        </button>
      </div>

      <Modal
        isOpen={isSingleModalOpen}
        onClose={() => setIsSingleModalOpen(false)}
      >
        <AddStudentSingle
          classList={classList}
          classId={classId}
          onStudentAdded={() => {
            refreshStudentTable();
            setIsSingleModalOpen(false); // Close modal after adding
          }}
        />
      </Modal>

      <Modal isOpen={isListModalOpen} onClose={() => setIsListModalOpen(false)}>
        <AddStudentList
          classList={classList}
          classId={classId}
          onStudentsAdded={() => {
            refreshStudentTable();
            setIsListModalOpen(false); // Close modal after adding
          }}
        />
      </Modal>

      <Modal isOpen={isFileModalOpen} onClose={() => setIsFileModalOpen(false)}>
        <AddStudentFile
          classList={classList}
          classId={classId}
          onStudentsAdded={() => {
            refreshStudentTable();
            setIsFileModalOpen(false); // Close modal after adding
          }}
          onComplete={() => setIsFileModalOpen(false)} // Also close modal if file upload is complete
        />
      </Modal>

      <DisplayEnrolledStudentsTable
        classList={classList}
        selectedClass={classId}
        key={refreshKey}
      />
    </div>
  );
};

export default AddStudent;
