import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import "./AdminPanel.css";

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalClasses, setTotalClasses] = useState(0);
  const [totalSignouts, setTotalSignouts] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUsersWithDetails = async () => {
        try {
          const usersCollectionRef = collection(firestore, "users");
          const querySnapshot = await getDocs(usersCollectionRef);
          let globalSignoutCount = 0;
          let globalClassCount = 0;

          const usersWithDetails = await Promise.all(
            querySnapshot.docs.map(async (userDoc) => {
              const userData = userDoc.data();
              const schoolId = userData.schoolId;
              const userId = userDoc.id;

              // Fetch school data
              let schoolData = {
                name: "N/A",
                address: "N/A",
                city: "N/A",
                state: "N/A",
                zipcode: "N/A",
              };

              if (schoolId) {
                const schoolDocRef = doc(firestore, "schools", schoolId);
                const schoolDoc = await getDoc(schoolDocRef);

                if (schoolDoc.exists()) {
                  const schoolInfo = schoolDoc.data();
                  schoolData = {
                    name: schoolInfo.schoolName,
                    address: schoolInfo.address,
                    city: schoolInfo.city,
                    state: schoolInfo.state,
                    zipcode: schoolInfo.zipcode,
                  };
                }
              }

              // Count students added by this user
              let studentCount = 0;
              const studentsQuery = query(
                collection(firestore, "students"),
                where("addedBy", "==", userId)
              );
              const studentSnapshot = await getDocs(studentsQuery);
              studentCount = studentSnapshot.size;

              // Count signouts in signedInHistory subcollection
              let signoutCount = 0;
              const signoutSubcollectionRef = collection(
                firestore,
                `users/${userId}/signedInHistory`
              );
              const signoutSnapshot = await getDocs(signoutSubcollectionRef);
              signoutCount = signoutSnapshot.size;
              globalSignoutCount += signoutCount;

              // Add active and archived classes to global class count
              const activeClassCount = userData.activeClasses
                ? userData.activeClasses.length
                : 0;
              const archivedClassCount = userData.archivedClasses
                ? userData.archivedClasses.length
                : 0;
              globalClassCount += activeClassCount + archivedClassCount;

              return {
                ...userData,
                fullName: `${userData.title || ""} ${
                  userData.firstName || ""
                } ${userData.lastName || ""}`.trim(),
                schoolName: schoolData.name,
                schoolAddress: `${schoolData.address}, ${schoolData.city}, ${schoolData.state} ${schoolData.zipcode}`,
                activeClassCount,
                archivedClassCount,
                studentCount,
                signoutCount,
              };
            })
          );

          setUsers(usersWithDetails);
          setTotalUsers(usersWithDetails.length);
          setTotalClasses(globalClassCount);
          setTotalSignouts(globalSignoutCount);
        } catch (error) {
          console.error("Error fetching users or related data:", error);
        }
      };

      fetchUsersWithDetails();
    }
  }, [isAuthenticated]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "admin" && password === "admin") {
      setIsAuthenticated(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Invalid username or password. Try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="admin-panel-login-modal">
        <div className="admin-panel-login-container">
          <h2>Admin Login</h2>
          <form onSubmit={handleLogin}>
            <div className="admin-panel-form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="admin-panel-form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && (
              <p className="admin-panel-error-message">{errorMessage}</p>
            )}
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-panel-container">
      <h1>Admin Panel - User Overview</h1>
      <div className="admin-panel-summary-boxes">
        <div className="admin-panel-summary-box">
          <h3>Total Users</h3>
          <h3>{totalUsers}</h3>
        </div>
        <div className="admin-panel-summary-box">
          <h3>Total Classes</h3>
          <h3>{totalClasses}</h3>
        </div>
        <div className="admin-panel-summary-box">
          <h3>Total Signouts</h3>
          <h3>{totalSignouts}</h3>
        </div>
      </div>
      <div className="admin-panel-table-container">
        <table className="table-admin">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>School Name</th>
              <th>School Address</th>
              <th>Active Classes</th>
              <th>Archived Classes</th>
              <th>Students Added</th>
              <th>Signouts</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.uid}>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{user.schoolName}</td>
                <td>{user.schoolAddress}</td>
                <td>{user.activeClassCount}</td>
                <td>{user.archivedClassCount}</td>
                <td>{user.studentCount}</td>
                <td>{user.signoutCount}</td>
                <td>{user.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPanel;
