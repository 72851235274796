import React, { useEffect, useState, useContext } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import SignInButton from "./SignInButton";
import { firestore } from "./FirebaseConfig";
import { UserContext } from "./UserContext";
import "./DisplaySignOut.css";

function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

const DisplaySignOut = () => {
  const [signOutData, setSignOutData] = useState([]);
  const { user } = useContext(UserContext);
  const uid = user?.uid;

  useEffect(() => {
    if (!uid) return;

    const currentSignOutsRef = collection(
      firestore,
      `users/${uid}/currentSignOuts`
    );
    const unsubscribe = onSnapshot(currentSignOutsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSignOutData(data);
    });

    return () => unsubscribe();
  }, [uid]);

  return (
    <div className="display-sign-out-container">
      <h2>Active Sign-Outs</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Time Out</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {signOutData.length === 0 ? (
            <tr>
              <td colSpan="4" className="no-data">
                No active sign-outs at the moment.
              </td>
            </tr>
          ) : (
            signOutData.map((signOut) => (
              <tr key={signOut.id}>
                <td>{signOut.studentName}</td>
                <td>{signOut.location}</td>
                <td>{formatTime(signOut.time_out)}</td>
                <td>
                  <SignInButton
                    id={signOut.id}
                    name={signOut.studentName}
                    idNumber={signOut.idNumber}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DisplaySignOut;
