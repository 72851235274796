import React, { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import useEnrolledStudents from "./useEnrolledStudents";
import { useUser } from "./UserContext";
import "./SignOutForm.css";

const SignOutForm = () => {
  const [idNumber, setIdNumber] = useState("");
  const [location, setLocation] = useState("");
  const [customLocation, setCustomLocation] = useState("");
  const { enrolledStudentsMap } = useEnrolledStudents();
  const { schoolId } = useUser();
  const { user } = useUser();

  const classroomName =
    user && user.displayName ? `${user.displayName}'s Classroom` : "Classroom";

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation(selectedLocation);
    if (selectedLocation !== "Other") {
      setCustomLocation(""); // Clear the custom location if not "Other"
    }
  };

  const searchStudent = async (idNumber) => {
    try {
      if (!idNumber.trim()) {
        console.error("ID Number cannot be empty.");
        return null;
      }

      const studentsRef = collection(firestore, "students");
      const q = query(
        studentsRef,
        where("studentId", "==", idNumber),
        where("schoolId", "==", schoolId),
        where("addedBy", "==", user.uid) // Ensure the teacher added the student
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.warn("No matching student found.");
        return null;
      }

      // Assuming studentId is unique, return the first match
      const matchedStudent = querySnapshot.docs[0];
      console.log("Matched Student:", matchedStudent.data());
      return matchedStudent.id;
    } catch (error) {
      console.error("Error searching for student:", error);
      return null;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const foundStudentId = await searchStudent(idNumber);

      if (foundStudentId) {
        const isEnrolled = enrolledStudentsMap[foundStudentId];
        if (isEnrolled) {
          const studentDocRef = doc(firestore, "students", foundStudentId);
          const studentData = (await getDoc(studentDocRef)).data();

          if (studentData) {
            const userRef = doc(firestore, "users", user.uid);
            const currentSignOutsRef = collection(userRef, "currentSignOuts");

            const signOutData = {
              idNumber,
              studentDocumentNumber: foundStudentId,
              studentName: studentData.studentName,
              time_out: new Date().toISOString(),
              time_in: null,
              location: location === "Other" ? customLocation : location,
              schoolId: studentData.schoolId,
              teacherUID: user.uid,
              grade: studentData.grade || null,
              classId: studentData.enrolledClasses[0], // Assuming a student belongs to one class
            };

            await addDoc(currentSignOutsRef, signOutData);
            setIdNumber("");
            setLocation("");
            setCustomLocation("");
          } else {
            console.error("Student data not found.");
          }
        } else {
          console.error("Student is not enrolled in your classes.");
        }
      } else {
        console.error("Student not found.");
      }
    } catch (error) {
      console.error("Error adding sign-out data:", error);
    }
  };

  return (
    <div className="form-container">
      <h2>{classroomName}</h2>
      <form className="form" onSubmit={handleFormSubmit}>
        <div className="form-row-input">
          <input
            type="text"
            placeholder="ID Number"
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
            required
          />
          <select value={location} onChange={handleLocationChange} required>
            <option value="">Select Location</option>
            <option value="Bathroom">Bathroom</option>
            <option value="Water Fountain">Water Fountain</option>
            <option value="Nurse">Nurse</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {location === "Other" && (
          <div className="custom-location-input">
            <input
              type="text"
              placeholder="Enter custom location"
              value={customLocation}
              onChange={(e) => setCustomLocation(e.target.value)}
              required
            />
          </div>
        )}
        <button type="submit">Sign Out</button>
      </form>
    </div>
  );
};

export default SignOutForm;
