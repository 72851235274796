import React, { useContext, useState, useEffect } from "react";
import {
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { UserContext } from "./UserContext";
import { firestore } from "./FirebaseConfig";
import { useNavigate, Link } from "react-router-dom";
import InviteModal from "./InviteModal";
import "./DisplayClasses.css";

const DisplayClasses = () => {
  const { user, schoolId } = useContext(UserContext);
  const [classList, setClassList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [selectedClassData, setSelectedClassData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching classes for user UID:", user?.uid);

        const q = query(
          collection(firestore, "classes"),
          where("teacherId", "==", user?.uid) // Match classes where teacherId equals the user's UID
        );

        const querySnapshot = await getDocs(q);
        const fetchedClasses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched classes:", fetchedClasses);

        // Ensure only active classes are displayed
        const activeClasses = fetchedClasses.filter(
          (cls) => !cls.isArchived || cls.isArchived === false
        );

        setClassList(activeClasses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching classes:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [user?.uid]);

  const handleInviteTeachers = (classData) => {
    setSelectedClassData(classData);
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setSelectedClassData(null);
  };

  const handleArchiveClass = async (classId) => {
    if (!window.confirm("Are you sure you want to archive this class?")) return;
    try {
      const classRef = doc(firestore, "classes", classId);
      const userRef = doc(firestore, "users", user.uid);

      await updateDoc(classRef, { isArchived: true });
      await updateDoc(userRef, {
        activeClasses: arrayRemove(classId),
        archivedClasses: arrayUnion(classId),
      });

      setClassList(classList.filter((cls) => cls.id !== classId));
    } catch (error) {
      console.error("Error archiving class:", error);
    }
  };

  const handleDeleteClass = async (classId) => {
    if (!window.confirm("Are you sure you want to delete this class?")) return;
    try {
      const classRef = doc(firestore, "classes", classId);
      const classDoc = await getDoc(classRef);

      if (classDoc.exists) {
        const { enrolledStudents = [] } = classDoc.data();

        for (const studentId of enrolledStudents) {
          const studentRef = doc(firestore, "students", studentId);
          await deleteDoc(studentRef);
        }
      }

      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, {
        activeClasses: arrayRemove(classId),
      });

      await deleteDoc(classRef);
      setClassList(classList.filter((cls) => cls.id !== classId));
    } catch (error) {
      console.error("Error deleting class:", error);
    }
  };

  const handleViewArchivedClasses = () => {
    navigate("/archivedclasses");
  };

  if (loading) {
    return <div className="loading-container">Loading...</div>;
  }

  return (
    <div className="display-classes-container">
      <h2></h2>
      <Link to="/addclass">
        <button>Add Class</button>
      </Link>

      {classList.length === 0 ? (
        <p>No classes found.</p>
      ) : (
        <div className="class-list">
          {classList.map((classData) => (
            <div key={classData.id} className="class-item">
              <div className="class-info">
                <h3>{classData.className}</h3>
                <p>
                  School Year: {classData.schoolYear} | Grade:{" "}
                  {classData.gradeLevel}
                </p>
                <p>
                  Enrolled Students: {classData.enrolledStudents?.length || 0}
                </p>
              </div>
              <div className="class-actions">
                <button onClick={() => handleInviteTeachers(classData)}>
                  Invite Teachers
                </button>
                <Link to={`/addstudent/${classData.id}`}>
                  <button>Add Students</button>
                </Link>
                <button onClick={() => handleArchiveClass(classData.id)}>
                  Archive
                </button>
                <button
                  className="red-button"
                  onClick={() => handleDeleteClass(classData.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <button onClick={handleViewArchivedClasses}>View Archived Classes</button>

      {isInviteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <InviteModal
              onClose={handleCloseInviteModal}
              user={user}
              classData={selectedClassData}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayClasses;
