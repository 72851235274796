import React, { useState, useContext, useEffect } from "react";
import { updateProfile, updateEmail, updatePassword } from "firebase/auth";
import { doc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { auth, firestore } from "./FirebaseConfig";
import { UserContext } from "./UserContext";
import SchoolDropdown from "./SchoolDropdown";
import AddSchoolModal from "./AddSchoolModal";
import "./Settings.css";

const Settings = () => {
  const { user } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [schoolDetails, setSchoolDetails] = useState("");
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        try {
          const userRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setDisplayName(userData.displayName || "");
            setEmail(userData.email || "");

            if (userData.schoolId) {
              const schoolRef = doc(firestore, "schools", userData.schoolId);
              const schoolDoc = await getDoc(schoolRef);

              if (schoolDoc.exists()) {
                const schoolData = schoolDoc.data();
                const formattedDetails = `${schoolData.schoolName}\n${schoolData.address}, ${schoolData.city}, ${schoolData.state} ${schoolData.zipcode}`;
                setSchoolDetails(formattedDetails);
                setSelectedSchool({
                  id: userData.schoolId,
                  ...schoolData,
                });
              } else {
                setSchoolDetails("School information not found.");
              }
            } else {
              setSchoolDetails("No school assigned.");
            }
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, [user]);

  const handleAddSchool = () => setShowAddSchoolModal(true);

  const handleUpdateProfile = async () => {
    setLoading(true);
    setFeedback("");

    try {
      const userRef = doc(firestore, "users", user.uid);

      if (displayName !== user.displayName) {
        await updateProfile(auth.currentUser, { displayName });
        await updateDoc(userRef, { displayName });
      }

      if (email !== user.email) {
        await updateEmail(auth.currentUser, email);
        await updateDoc(userRef, { email });
      }

      if (password) {
        await updatePassword(auth.currentUser, password);
      }

      if (selectedSchool) {
        await updateDoc(userRef, { schoolId: selectedSchool.id });
        const schoolRef = doc(firestore, "schools", selectedSchool.id);
        await updateDoc(schoolRef, {
          teachers: arrayUnion(user.uid),
        });

        const formattedDetails = `${selectedSchool.schoolName}, ${selectedSchool.address}, ${selectedSchool.city}, ${selectedSchool.state} ${selectedSchool.zipcode}`;
        setSchoolDetails(formattedDetails);
      }

      setFeedback("Profile updated successfully!");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setFeedback("Error updating profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="settings-container">
      <h2>Account Settings</h2>

      {/* Display Name */}
      <div className="settings-field">
        <label htmlFor="displayName">Display Name</label>
        {!isEditing ? (
          <p>{displayName || "N/A"}</p>
        ) : (
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        )}
      </div>

      {/* Email */}
      <div className="settings-field">
        <label htmlFor="email">Email</label>
        {!isEditing ? (
          <p>{email || "N/A"}</p>
        ) : (
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
      </div>

      {/* Password */}
      {isEditing && (
        <div className="settings-field">
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Leave blank to keep current password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      )}

      {/* School Details */}
      <div className="settings-field">
        <label htmlFor="schoolDetails">School</label>
        {!isEditing ? (
          <pre className="school-details">{schoolDetails || "N/A"}</pre>
        ) : (
          <SchoolDropdown
            onSelectSchool={setSelectedSchool}
            onAddSchool={handleAddSchool}
          />
        )}
      </div>

      {/* Feedback */}
      {feedback && <div className="settings-feedback">{feedback}</div>}

      {/* Buttons */}
      <div className="settings-buttons">
        {!isEditing ? (
          <button onClick={() => setIsEditing(true)}>Edit Profile</button>
        ) : (
          <>
            <button onClick={handleUpdateProfile} disabled={loading}>
              {loading ? "Updating..." : "Save Changes"}
            </button>
            <button className="red-button" onClick={() => setIsEditing(false)}>
              Cancel
            </button>
          </>
        )}
      </div>

      {/* Add School Modal */}
      {showAddSchoolModal && (
        <AddSchoolModal
          onClose={() => setShowAddSchoolModal(false)}
          onSchoolAdded={(newSchoolData) => {
            setSelectedSchool(newSchoolData);
            setShowAddSchoolModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Settings;
