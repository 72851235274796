import React, { useEffect, useState, useContext } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import { UserContext } from "./UserContext";
import SignOutLocationChart from "./SignOutLocationChart";
import SignOutDayChart from "./SignOutDayChart";
import TopSignOutsChart from "./TopSignOutsChart";
import SignOutTimePeriodChart from "./SignOutTimePeriodChart";
import useFetchClasses from "./useFetchClasses";
import DatePicker from "react-datepicker";
import Modal from "./Modal";
import "react-datepicker/dist/react-datepicker.css";
import "./DisplaySignOut.css";

function SignOutsTeacherRecord() {
  const user = useContext(UserContext);
  const { classList } = useFetchClasses();
  const [selectedClass, setSelectedClass] = useState("");
  const [signOutData, setSignOutData] = useState([]);
  const [filteredSignOutData, setFilteredSignOutData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRangeFilter, setDateRangeFilter] = useState("thisSchoolYear");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("location");

  useEffect(() => {
    const fetchSignOuts = async () => {
      try {
        let signOutsRef = collection(
          firestore,
          "users",
          user.uid,
          "signedInHistory"
        );

        if (selectedClass) {
          signOutsRef = query(
            signOutsRef,
            where("classId", "==", selectedClass)
          );
        }

        const querySnapshot = await getDocs(signOutsRef);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedData = data.sort((a, b) => {
          const timeA = new Date(a.time_out).getTime();
          const timeB = new Date(b.time_out).getTime();
          return timeB - timeA; // Most recent first
        });

        setSignOutData(sortedData);
      } catch (error) {
        console.error("Error fetching sign-out data:", error);
      }
    };

    fetchSignOuts();
  }, [user.uid, selectedClass]);

  useEffect(() => {
    const getDateRange = () => {
      const now = new Date();
      const firstDayOfWeek = new Date(
        now.setDate(now.getDate() - now.getDay())
      );
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const firstDayOfSchoolYear = new Date(
        now.getMonth() >= 6 ? now.getFullYear() : now.getFullYear() - 1,
        7, // August
        1
      );

      switch (dateRangeFilter) {
        case "today":
          return [new Date().setHours(0, 0, 0, 0), new Date()];
        case "thisWeek":
          return [firstDayOfWeek, new Date()];
        case "thisMonth":
          return [firstDayOfMonth, new Date()];
        case "thisSchoolYear":
          return [firstDayOfSchoolYear, new Date()];
        default:
          return [startDate, endDate];
      }
    };

    const [start, end] = getDateRange();
    const filteredData = signOutData.filter((signOut) => {
      const timeOutDate = new Date(signOut.time_out);
      return (
        (!start || timeOutDate >= start) &&
        (!end || timeOutDate <= end) &&
        (!searchTerm ||
          signOut.studentName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          signOut.idNumber.includes(searchTerm) ||
          signOut.location.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });

    setFilteredSignOutData(filteredData);
  }, [signOutData, searchTerm, dateRangeFilter, startDate, endDate]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case "location":
        return <SignOutLocationChart data={filteredSignOutData} />;
      case "day":
        return <SignOutDayChart data={filteredSignOutData} />;
      case "time":
        return <SignOutTimePeriodChart data={filteredSignOutData} />;
      case "top":
        return <TopSignOutsChart data={filteredSignOutData} />;
      default:
        return null;
    }
  };

  return (
    <div className="table-container">
      <h1>{user.displayName}'s Sign-Out Record</h1>

      {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name, student ID, or location"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Class and Date Filters */}
      <div className="filter-container">
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">All Classes</option>
          {classList.map((classItem) => (
            <option key={classItem.id} value={classItem.id}>
              {classItem.className || "Unnamed Class"}
            </option>
          ))}
        </select>

        <select
          value={dateRangeFilter}
          onChange={(e) => setDateRangeFilter(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
          <option value="thisSchoolYear">This School Year</option>
          <option value="custom">Custom</option>
        </select>

        {dateRangeFilter === "custom" && (
          <div className="date-picker-container">
            <div className="date-picker-item">
              <label htmlFor="start-date">Start Date:</label>
              <DatePicker
                id="start-date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="date-picker-item">
              <label htmlFor="end-date">End Date:</label>
              <DatePicker
                id="end-date"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        )}
      </div>

      {/* Sign-Out Table */}
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Grade</th>
            <th>Name</th>
            <th>Location</th>
            <th>Time Out</th>
            <th>Time In</th>
            <th>Time Difference</th>
          </tr>
        </thead>
        <tbody>
          {filteredSignOutData.map((signOut) => (
            <tr key={signOut.id}>
              <td>{new Date(signOut.time_out).toLocaleDateString()}</td>
              <td>{signOut.grade}</td>
              <td>{signOut.studentName}</td>
              <td>{signOut.location}</td>
              <td>
                {new Date(signOut.time_out).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </td>
              <td>
                {signOut.time_in
                  ? new Date(signOut.time_in).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "Not signed in"}
              </td>
              <td>
                {signOut.time_in
                  ? (() => {
                      const diffInMs =
                        new Date(signOut.time_in).getTime() -
                        new Date(signOut.time_out).getTime();
                      const diffInMinutes = Math.floor(diffInMs / 60000);
                      const hours = Math.floor(diffInMinutes / 60);
                      const minutes = diffInMinutes % 60;
                      return `${hours > 0 ? `${hours}h ` : ""}${minutes}m`;
                    })()
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Insights */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="modal-button"
        style={{ marginBottom: "100px" }}
      >
        View Insights
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        type="insight"
      >
        <h2>Sign-Out Insights</h2>

        {/* Render Tabs */}
        {renderActiveTab()}
      </Modal>
    </div>
  );
}

export default SignOutsTeacherRecord;
