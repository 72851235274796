import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const TopSignOutsChart = ({ data }) => {
  // Aggregate sign-out counts by student name
  const aggregatedData = data.reduce((acc, record) => {
    const name = record.studentName || "Unknown";
    acc[name] = (acc[name] || 0) + 1;
    return acc;
  }, {});

  // Convert aggregated data into an array and sort by sign-out count
  const chartData = Object.entries(aggregatedData)
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count) // Sort by descending count
    .slice(0, 10); // Take the top 10

  return (
    <div style={{ width: "100%", marginBottom: "40px", height: "400px" }}>
      <h2>Top 10 Students by Sign-Out Count</h2>
      <ResponsiveContainer>
        <BarChart data={chartData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TopSignOutsChart;
