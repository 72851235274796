import React from "react";
import attendance from "../images/attendance.png";
import signout from "../images/signout.png";
import checklist from "../images/checklist.png";
import "./About.css";
import { Form } from "react-bootstrap";

const About = () => {
  const features = [
    {
      title: "Attendance",
      description:
        "With our Attendance feature, students can click on their name to mark their arrival. Their name will disappear from the list, and the system will automatically log the time they showed up. This streamlines roll call and keeps a precise record of attendance.",
      image: attendance, // Replace with the actual path to your image
    },
    {
      title: "Sign Out",
      description:
        "Students can sign out of the classroom using their user ID or class ID. This feature is especially useful during fire alarms or other emergencies, ensuring you know exactly where each student is at any given time.",
      image: signout, // Replace with the actual path to your image
    },
    {
      title: "Checklist",
      description:
        "Use the Checklist for quick tasks like field trips, emergency drills, or even organizing classroom projects. It’s a versatile tool for keeping track of important tasks, whether it’s ensuring every child has their permission slip or checking off students’ pictures on a bulletin board.",
      image: checklist, // Replace with the actual path to your image
    },
  ];

  return (
    <div className="about-container">
      <h1>About classBoon</h1>
      <p className="about-intro">
        Welcome to classBoon, a platform designed by a 5th-grade teacher to
        simplify classroom management. Our tools are intuitive, reliable, and
        created with the needs of teachers in mind.
      </p>

      {features.map((feature, index) => (
        <div
          key={index}
          className={`feature-section ${
            index % 2 === 0 ? "image-left" : "image-right"
          }`}
        >
          <div className="feature-content">
            <div className="feature-text">
              <h2>{feature.title}</h2>
              <p>{feature.description}</p>
            </div>
            <div className="feature-image">
              <img
                src={feature.image}
                alt={`${feature.title} illustration`}
                className="feature-img"
              />
            </div>
          </div>
        </div>
      ))}

      <div className="about-footer">
        Simplify your classroom management today with classBoon. Your students’
        success, organized.
      </div>
    </div>
  );
};

export default About;
