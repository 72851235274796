import React, { useState } from "react";
import "./Modal.css"; // Use the same CSS file as InviteModal

const PreviewAdjustModal = ({ parsedStudents, onClose, onSave }) => {
  const [students, setStudents] = useState(parsedStudents);

  const handleFieldChange = (index, field, value) => {
    setStudents((prev) =>
      prev.map((student, i) =>
        i === index ? { ...student, [field]: value } : student
      )
    );
  };

  const handleSave = () => {
    onSave(students); // Pass updated students back to parent component
    onClose(); // Close the modal
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-2">
        <h2>Preview and Adjust Student Data</h2>
        <div className="form-row">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Student ID</th>
                <th>Grade</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={student.firstName || ""}
                      onChange={(e) =>
                        handleFieldChange(index, "firstName", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={student.lastName || ""}
                      onChange={(e) =>
                        handleFieldChange(index, "lastName", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={student.id || ""}
                      onChange={(e) =>
                        handleFieldChange(index, "id", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={student.grade || ""}
                      onChange={(e) =>
                        handleFieldChange(index, "grade", e.target.value)
                      }
                    />
                  </td>
                  <td
                    style={{
                      color: student.isValid ? "green" : "red",
                    }}
                  >
                    {student.isValid ? "Valid" : "Error"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-buttons">
          <button onClick={handleSave}>Save and Import</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PreviewAdjustModal;
