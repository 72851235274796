import React, { useState, useContext, useEffect } from "react";
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { auth } from "./FirebaseConfig"; // Import auth from your Firebase config
import { validateStudents, parseStudentList } from "./StudentHelpers";
import PreviewAdjustModal from "./PreviewAdjustModal";

const AddStudentList = ({ classList, classId, onStudentsAdded }) => {
  const [pastedStudentList, setPastedStudentList] = useState("");
  const [parsedStudents, setParsedStudents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);

  useEffect(() => {
    const fetchUserSchoolId = async () => {
      try {
        const db = getFirestore();
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSchoolId(userData.schoolId);
        } else {
          console.error("User document not found in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserSchoolId();
  }, []);

  const handlePasteChange = (e) => {
    const parsed = parseStudentList(e.target.value).map((student) => ({
      ...student,
      firstName: student.studentName?.split(" ")[0] || "",
      lastName: student.studentName?.split(" ").slice(1).join(" ") || "",
    }));
    const validated = validateStudents(parsed);
    setParsedStudents(validated);
    setPastedStudentList(e.target.value);
  };

  const handleSave = async (updatedStudents) => {
    const validStudents = updatedStudents.filter((student) => student.isValid);

    if (!classId || validStudents.length === 0) {
      alert("No class selected or no valid students to import.");
      return;
    }

    if (!schoolId) {
      alert("School ID is missing. Please ensure your profile is complete.");
      return;
    }

    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", classId);
      const studentCollectionRef = collection(db, "students");

      const newStudentIds = [];
      for (const student of validStudents) {
        const newStudent = {
          firstName: student.firstName,
          lastName: student.lastName,
          studentName: `${student.firstName} ${student.lastName}`,
          studentId: student.id,
          grade: student.grade,
          enrolledClasses: [classId],
          addedBy: auth.currentUser?.uid || "unknown", // Fallback for addedBy
          schoolId, // Use fetched schoolId
        };

        const studentDocRef = await addDoc(studentCollectionRef, newStudent);
        newStudentIds.push(studentDocRef.id);
      }

      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(...newStudentIds),
      });

      alert(`${validStudents.length} students were successfully imported.`);
      setParsedStudents([]);
      setPastedStudentList("");
      if (onStudentsAdded) {
        onStudentsAdded(); // Refresh the table
      }
    } catch (error) {
      console.error("Error during bulk import:", error);
      alert("Failed to import students. Please try again.");
    }
  };

  return (
    <div>
      <h2>Try the Bulk Importer</h2>
      <textarea
        value={pastedStudentList}
        onChange={handlePasteChange}
        placeholder="Paste student list here. Columns: Name | Student ID | Grade"
        style={{ height: "200px", fontSize: "16px" }}
      />
      {parsedStudents.length > 0 && (
        <button onClick={() => setIsModalOpen(true)}>Preview and Adjust</button>
      )}
      {isModalOpen && (
        <PreviewAdjustModal
          parsedStudents={parsedStudents}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AddStudentList;
