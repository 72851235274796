import React, { useState, useContext } from "react";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getFirestore,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { auth } from "./FirebaseConfig";
import { UserContext } from "./UserContext";

const gradeLevelOptions = [
  "Pre-K",
  "Kindergarten",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const AddStudentSingle = ({ classList, classId, onStudentAdded }) => {
  const { user } = useContext(UserContext);
  const [selectedClass, setSelectedClass] = useState(classId || "");
  const [selectedGrade, setSelectedGrade] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const studentId = event.target.studentId.value;
    const studentName = `${firstName} ${lastName}`;

    try {
      const db = getFirestore();
      const studentsCollectionRef = collection(db, "students");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const newStudentData = {
        firstName,
        lastName,
        studentName,
        studentId,
        grade: selectedGrade,
        enrolledClasses: [selectedClass],
        schoolId: userData.schoolId,
        addedBy: auth.currentUser.uid,
      };

      const studentDocRef = await addDoc(studentsCollectionRef, newStudentData);

      const classDocRef = doc(db, "classes", selectedClass);
      await updateDoc(classDocRef, {
        enrolledStudents: arrayUnion(studentDocRef.id),
      });

      if (onStudentAdded) onStudentAdded();
      event.target.reset();
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Individual Student</h2>
      <div>
        <label htmlFor="firstName">First Name:</label>
        <input type="text" id="firstName" name="firstName" required />
      </div>
      <div>
        <label htmlFor="lastName">Last Name:</label>
        <input type="text" id="lastName" name="lastName" required />
      </div>
      <div>
        <label htmlFor="studentId">Student ID:</label>
        <input type="text" id="studentId" name="studentId" required />
      </div>
      <div>
        <label htmlFor="grade">Grade:</label>
        <select
          id="grade"
          name="grade"
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
          required
        >
          <option value="">-- Select Grade --</option>
          {gradeLevelOptions.map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </select>
      </div>
      <button type="submit">Add Student</button>
    </form>
  );
};

export default AddStudentSingle;
